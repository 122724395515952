<template>

  <div>
  <Navbar />

    <!-- Hero Start -->
    <section class="bg-half-170 pb-0 d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="title-heading text-center mt-2 mb-5">

              <h6 class="text-primary mt-3 font-weight-bold">Наш сотрудник свяжется с вами в ближайшее время. </h6>
              <h1 class="heading mb-3">
                <check-circle-icon
                    class="fea icon-ex-lg text-primary mr-2"
                ></check-circle-icon
                >  Успех!
              </h1>
            </div>
          </div>

        </div>

      </div>

    </section>


    <Footer />
</div>

</template>


<script>
import {CheckCircleIcon,XCircleIcon} from "vue-feather-icons";
import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";
import store from "@/store/store";

export default {
  name: "payment-status",
  components: {
    Navbar,
    Footer,
    CheckCircleIcon,
    XCircleIcon,

  },
  data() {
    return{
      in_sql: {
        email: '',
      }
    }
  },
  mounted() {


    this.axios({
      method: 'get',
      url: '' + this.$hostname + '/email-letters/webinar-register/'+this.$route.params.email,
    }).then((res) => {
      console.log('inside webinar register')

    });
  }
}
</script>

